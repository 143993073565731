var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"white","height":"56"}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-toolbar',[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"grey lighten-5","size":"70"}},[_c('v-img',{attrs:{"contain":"","max-height":"70%","src":require("@/assets/logo.png")}})],1),_c('v-toolbar-title',{staticClass:"font-weight-black headline"},[_vm._v("EB Europe")]),_c('v-spacer'),_c('v-toolbar-items',[_c('router-link',{staticClass:"v-btn v-btn--router v-size--default",attrs:{"to":{name: 'Home'}}},[_c('a',{attrs:{"aria-current":"Home"}},[_c('v-icon',[_vm._v("mdi-home")])],1)])],1),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":0,"color":"grey lighten-5"},on:{"click":function($event){return _vm.$router.push('Forstwirtschaft')}}},'v-btn',attrs,false),on),[_vm._v(" Forstwirtschaft ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Forstwirtschaft', hash: '#Digitalisierung-und-Georeferenzierung' })}}},[_c('v-list-item-title',[_vm._v("Digitalisierung und Georeferenzierung")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Forstwirtschaft', hash: '#Gesundheitskartierung-Inventarisierung-und-Monitoring' })}}},[_c('v-list-item-title',[_vm._v("Gesundheitskartierung, Inventarisierung und Monitoring")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Forstwirtschaft', hash: '#Integration' })}}},[_c('v-list-item-title',[_vm._v("Integration")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":0,"color":"grey lighten-5"},on:{"click":function($event){return _vm.$router.push('Landwirtschaft')}}},'v-btn',attrs,false),on),[_vm._v(" Landwirtschaft ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Landwirtschaft', hash: '#Schadensaufnahme' })}}},[_c('v-list-item-title',[_vm._v("Schadensaufnahme")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Landwirtschaft', hash: '#Trockenstress' })}}},[_c('v-list-item-title',[_vm._v("Trockenstress")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Landwirtschaft', hash: '#Naehrstoffbedarf-und-Ertragspotential' })}}},[_c('v-list-item-title',[_vm._v("Nährstoffbedarf und Ertragspotential")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":0,"color":"grey lighten-5"},on:{"click":function($event){return _vm.$router.push('Vermessung')}}},'v-btn',attrs,false),on),[_vm._v(" Vermessung ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Vermessung', hash: '#Tief-und-Erdbau' })}}},[_c('v-list-item-title',[_vm._v("Tief- und Erdbau")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Vermessung', hash: '#Flaechenvermessung' })}}},[_c('v-list-item-title',[_vm._v("Flächenvermessung")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Vermessung', hash: '#3D-Modelle' })}}},[_c('v-list-item-title',[_vm._v("3D-Modelle")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Vermessung', hash: '#Thermoanalyse' })}}},[_c('v-list-item-title',[_vm._v("Thermoanalyse")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":0,"color":"grey lighten-5"},on:{"click":function($event){return _vm.$router.push('Beratung')}}},'v-btn',attrs,false),on),[_vm._v(" Beratung ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Beratung', hash: '#Planung-und-Konzeption' })}}},[_c('v-list-item-title',[_vm._v("Planung & Konzeption")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Beratung', hash: '#Sourcing' })}}},[_c('v-list-item-title',[_vm._v("Sourcing")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Beratung', hash: '#Technische-Implementierung' })}}},[_c('v-list-item-title',[_vm._v("Technische Implementierung")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Beratung', hash: '#Support' })}}},[_c('v-list-item-title',[_vm._v("Support")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ path: 'Beratung', hash: '#Uebernahme-von-Prozessen' })}}},[_c('v-list-item-title',[_vm._v("Übernahme von Prozessen")])],1)],1)],1),_c('router-link',{staticClass:"v-btn v-btn--router v-size--default",attrs:{"to":{name: 'Kontakt'}}},[_c('a',[_vm._v("Kontakt")])])],1),_c('v-toolbar-items',{staticClass:"hidden-md-and-up"},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":1000,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"Seitennavigations-menü"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('Forstwirtschaft')}}},[_c('v-list-item-title',[_vm._v("Forstwirtschaft")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('Landwirtschaft')}}},[_c('v-list-item-title',[_vm._v("Landwirtschaft")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('Vermessung')}}},[_c('v-list-item-title',[_vm._v("Vermessung")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('Beratung')}}},[_c('v-list-item-title',[_vm._v("Beratung")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('Kontakt')}}},[_c('v-list-item-title',[_vm._v("Kontakt")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }