<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h1 class="display-2 font-weight-bold mb-3">Dienstleistungen auf einen Blick</h1>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive
        class="mx-auto title font-weight-light mb-8"
        max-width="720"
        >Durch den Einsatz modernster Hyperspektral-, Thermal- und Lasermesstechnik, bieten wir unseren Kunden zentimetergenaue Kartierung, 3D-Rekonstruktion, K.I. gestützte Auswertung und wissenschaftliche Analyse von Vital- und Bestandsdaten. Hochauflösende Satellitenbilder und unsere Drohnenplattformen für Kurz- und Langstreckenflüge ermöglichen maximale Einsatzflexibilität und projektspezifische Anpassungsmöglichkeiten.
        <br><br>
        Mit unserer Expertise in Fernerkundung, Vermessung und Pflanzenwissenschaften möchten wir Ihr Projekt begleiten und unterstützen.
        <br><br>
        Die Möglichkeiten reichen hierbei von einer Momentaufnahme zur Schadens-, Zustandsbeurteilung oder Einzelvermessung bis hin zu einer komplexen und mit dem Kunden abgestimmten Projektbegleitung und Beratung.
        <br><br>
        Gerne unterstützen wir Sie bei der Digitalisierung und Etablierung von Server- und Softwarelösungen, zur Integration von Karten und Messdaten in Ihren Arbeitsalltag als stets  verlässlicher Partner.

      </v-responsive>
              <v-btn aria-label="Weiter zur Leistungsübersicht" class="align-self-end" fab outlined @click="$vuetify.goTo('#services')">
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>

    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "About"
};
</script>