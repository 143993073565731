<template>
  <v-main>
    <Cover
      textclass="white--text text-center"
      coverurl="https://images.unsplash.com/photo-1521366877222-57fbd54aa39f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2135&q=80"
      alt="Luftbild Landschaft Drohne Fernerkundung"
      text1="EB Europe"
      text2="Ihr Partner für Fernerkundung, Vermessung und Digitalisierung"
    />
    <About />
    <Services />
    <Partner />
    <!--<Contact />-->
  </v-main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/sections/Cover.vue";
import Services from "@/components/sections/Services.vue";
import About from "@/components/sections/About.vue";
import Partner from "@/components/sections/Partner.vue"

export default {
  name: "Home",
  components: {
    Cover,
    Services,
    About,
    Partner
  }
};
</script>
