<template>
  <v-footer light padless>
    <v-card flat tile class="secondary lighten-1 white--text">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card-text>
            
          </v-card-text>
        </v-col>
        <v-col class="grey--text" cols="12" sm="4">
          <v-card-text align="center">
            <router-link :to="{name: 'Impressum'}">
                <strong>Impressum</strong>
            </router-link>
            <br />Informationspflicht laut § 5 TMG.
            <br />
            EB Europe GmbH - 
            Agathaplatz 1 -
            63739 Aschaffenburg -
            Deutschland
            <br />E-Mail:info[at]eb-europe.de
          </v-card-text>
        </v-col>
        <v-col class="white--text" cols="12" sm="4">
          <v-card-text>
           
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} -
        <strong>EB Europe</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.v-application .secondary.lighten-1 {
    width: 100%;
}
strong {
  color: white
}
</style>