<template>
  <v-app-bar app color="white" height="56">
    <v-layout row justify-center>
      <v-toolbar>
        <v-avatar class="mr-3" color="grey lighten-5" size="70">
          <v-img contain max-height="70%" src="@/assets/logo.png"></v-img>
        </v-avatar>
        <v-toolbar-title class="font-weight-black headline">EB Europe</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <router-link :to="{name: 'Home'}" class="v-btn v-btn--router v-size--default">
            <a aria-current="Home">
              <v-icon>mdi-home</v-icon>
            </a>
          </router-link>
        </v-toolbar-items>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-menu
                                      open-on-hover
                                      bottom
                                      offset-y>
                                      <template 
                                      v-slot:activator="{ on, attrs }">
                                          <v-btn
                                                :elevation="0"
                                                color="grey lighten-5"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="$router.push('Forstwirtschaft')">
                                                
                                                Forstwirtschaft
        
                                                   
                                          </v-btn>
                                      </template>

                                                          <v-list>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Forstwirtschaft', hash: '#Digitalisierung-und-Georeferenzierung' })">
                                                                      <v-list-item-title>Digitalisierung und Georeferenzierung</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Forstwirtschaft', hash: '#Gesundheitskartierung-Inventarisierung-und-Monitoring' })">
                                                                      <v-list-item-title>Gesundheitskartierung, Inventarisierung und Monitoring</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Forstwirtschaft', hash: '#Integration' })">
                                                                      <v-list-item-title>Integration</v-list-item-title>
                                                                </v-list-item>
                                                          </v-list>
            </v-menu>
            <v-menu
                                      open-on-hover
                                      bottom
                                      offset-y>
                                      <template 
                                      v-slot:activator="{ on, attrs }">
                                          <v-btn
                                                :elevation="0"
                                                color="grey lighten-5"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="$router.push('Landwirtschaft')">
                                                
                                                Landwirtschaft
        
                                                   
                                          </v-btn>
                                      </template>

                                                          <v-list>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Landwirtschaft', hash: '#Schadensaufnahme' })">
                                                                      <v-list-item-title>Schadensaufnahme</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Landwirtschaft', hash: '#Trockenstress' })">
                                                                      <v-list-item-title>Trockenstress</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Landwirtschaft', hash: '#Naehrstoffbedarf-und-Ertragspotential' })">
                                                                      <v-list-item-title>Nährstoffbedarf und Ertragspotential</v-list-item-title>
                                                                </v-list-item>
                                                          </v-list>
              </v-menu>
              <v-menu
                                      open-on-hover
                                      bottom
                                      offset-y>
                                      <template 
                                      v-slot:activator="{ on, attrs }">
                                          <v-btn
                                                :elevation="0"
                                                color="grey lighten-5"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="$router.push('Vermessung')">
                                                
                                                Vermessung
        
                                                   
                                          </v-btn>
                                      </template>

                                                          <v-list>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Vermessung', hash: '#Tief-und-Erdbau' })">
                                                                      <v-list-item-title>Tief- und Erdbau</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Vermessung', hash: '#Flaechenvermessung' })">
                                                                      <v-list-item-title>Flächenvermessung</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Vermessung', hash: '#3D-Modelle' })">
                                                                      <v-list-item-title>3D-Modelle</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Vermessung', hash: '#Thermoanalyse' })">
                                                                      <v-list-item-title>Thermoanalyse</v-list-item-title>
                                                                </v-list-item>
                                                          </v-list>
                </v-menu>
                <v-menu
                                      open-on-hover
                                      bottom
                                      offset-y>
                                      <template 
                                      v-slot:activator="{ on, attrs }">
                                          <v-btn
                                                :elevation="0"
                                                color="grey lighten-5"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="$router.push('Beratung')">
                                                
                                                Beratung
        
                                                   
                                          </v-btn>
                                      </template>

                                                          <v-list>
                                                                 <v-list-item
                                                                  @click="$router.push({ path: 'Beratung', hash: '#Planung-und-Konzeption' })">
                                                                      <v-list-item-title>Planung & Konzeption</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Beratung', hash: '#Sourcing' })">
                                                                      <v-list-item-title>Sourcing</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Beratung', hash: '#Technische-Implementierung' })">
                                                                      <v-list-item-title>Technische Implementierung</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Beratung', hash: '#Support' })">
                                                                      <v-list-item-title>Support</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                  @click="$router.push({ path: 'Beratung', hash: '#Uebernahme-von-Prozessen' })">
                                                                      <v-list-item-title>Übernahme von Prozessen</v-list-item-title>
                                                                </v-list-item>
                                                          </v-list>
                  </v-menu>
          <router-link :to="{name: 'Kontakt'}" class="v-btn v-btn--router v-size--default">
            <a>Kontakt</a>
          </router-link>
        </v-toolbar-items>

        <v-toolbar-items class="hidden-md-and-up">
          <v-menu :close-on-content-click="true" :nudge-width="1000" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn aria-label="Seitennavigations-menü" v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list >
              <v-list-item
                @click="$router.push('Forstwirtschaft')"
              >
                <v-list-item-title>Forstwirtschaft</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$router.push('Landwirtschaft')"
              >
                <v-list-item-title>Landwirtschaft</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$router.push('Vermessung')"
              >
                <v-list-item-title>Vermessung</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$router.push('Beratung')"
              >
                <v-list-item-title>Beratung</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$router.push('Kontakt')"
              >
                <v-list-item-title>Kontakt</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
        <!--<v-toolbar-items>
          <v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn>
        </v-toolbar-items>-->
      </v-toolbar>
    </v-layout>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped>
.v-application a {
  color: rgba(0, 0, 0, 0.87);
}
</style>
