<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Dienstleistung für die Landwirtschaft</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-responsive
              class="mx-auto title font-weight-light mb-8, text-left"
              max-width="720">
              <h1 id="Schadensaufnahme">Schadensaufnahme</h1><br>
                  Sei es Verbiss, oder Krankheits-/Schädlingsbefall. Im Schadensfall kommt es auf eine schnelle Identifizierung Bewertung an. Wir bieten extrem hohe Flächenleistung (bis zu 500 ha am Tag). Durch die Verwendung hochmoderner Hyperspektraltechnik können auch im Krankheit-/Schädlingsbefall nicht nur abgestorbene, sondern auch infizierte Kulturen frühzeitig identifiziert und kartiert werden.
              <br><br>
              <h2 id="Trockenstress">Trockenstress</h2><br>
                  Der Einsatz von Thermaltechnik ermöglicht eine Trockenstresskartierung aus der Luft. Feldbereiche mit hohem Wasserbedarf oder niedriger Wasserverfügbarkeit können so ermittelt werden.
              <br><br>
              <h3 id="Naehrstoffbedarf-und-Ertragspotential">Nährstoffbedarf und Ertragspotential</h3><br>
                  Lokale Bodenbeschaffenheit, Nährstoffbedarf, Mikroklima, Sonneneinstrahlung, Schädlings- und Krankheitsbefall sorgen kombiniert mit dem genetischen Potenzial der angebauten Nutzpflanzen für eine inhomogene Ertragsverteilung auf großen Flächen. Der Nährstoffbedarf sowie der allgemeine Gesundheitszustand können kartiert und für eine optimale Bewirtschaftungsstrategie analysiert werden. Entsprechend der jeweiligen Fläche können hierfür auch hochauflösende (bis zu 50 cm) Satellitenaufnahmen herangezogen werden.
        
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="6">
          <v-responsive
              class="mx-auto title font-weight-light mb-8, text-left"
              max-width=100%>
              <v-img
                contain
                max-width=100%
                src="@/assets/Feld.jpg" alt="NDVI Aufnahme Landwirtschaft Feld"
                lazy-src="@/assets/Feld_Lazy.jpg">
                        <template v-slot:placeholder>
                        <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center">
                                    <v-progress-circular
                                      :size="70"
                                      :width="7"
                                      :value="value"
                                         class="v-progress-circular--visible"
                                         color="grey lighten-5">
                                    </v-progress-circular>
                        </v-row>
                        </template>
                </v-img>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "Service-Landwirtschaft",
    data () {
      return {
        interval: {},
        value: 0,
      }
    },
    beforeDestroy () {
      clearInterval(this.interval)
    },
    mounted () {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 10
      }, 1000)
    },   
};
</script>