<template>
  <v-sheet id="contact" color="#333333" dark tag="section" tile>
    <div class="py-12"></div>

      <iframe width="100%" height= "900px" align="center" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=vvMdU2PNv02ItqV3b2DK6r28RC9f3TtHtbIBgk2wYiZUMzZCVERJWFBCUFJaRkJTRTRFMEtNTldQVC4u&embed=true" alt="Kontaktformular" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>

    <div class="py-12"></div>
  </v-sheet>
</template>

<script>
export default {
    name: "Contact"
}
</script>