<template>
  <section id="servicessection">
    <div class="py-12"></div>

    <v-container class="text-center">
      <!--<h2 class="display-2 font-weight-bold mb-3">SERVICES</h2>-->

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <!--<v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto" max-width="344">
                <v-img
                  src="https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80"
                  height="200px"
                ></v-img>

                <v-card-title>Forstwirtschaft</v-card-title>

                <v-card-subtitle>blablabla</v-card-subtitle>

                <v-card-actions>
                  <v-btn color="green" :to="{name: 'Forstwirtschaft'}" text>Mehr Infos</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.</v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto" max-width="344">
                <v-img
                  src="https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80"
                  height="200px"
                ></v-img>

                <v-card-title>Landwirtschaft</v-card-title>

                <v-card-subtitle>blablabla</v-card-subtitle>

                <v-card-actions>
                  <v-btn color="green" :to="{name: 'Landwirtschaft'}" text>Mehr Infos</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.</v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto" max-width="344">
                <v-img
                  src="https://images.unsplash.com/photo-1492114011589-509c6e695d8e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80"
                  height="200px"
                ></v-img>

                <v-card-title>Bauwirtschaft</v-card-title>

                <v-card-subtitle>blablabla</v-card-subtitle>

                <v-card-actions>
                  <v-btn color="green" :to="{name: 'Bauwirtschaft'}" text>Mehr Infos</v-btn>

                  <v-spacer></v-spacer>

                  <v-btn icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>I'm a thing. ut, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.</v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>-->

      <!--<v-responsive>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="3">
            <v-avatar class="elevation-12 mb-12" size="128">
              <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
            </v-avatar>
            <h2>Paul Elsholz</h2>
          </v-col>
          <v-col cols="12" sm="3">
            <v-avatar class="elevation-12 mb-12" size="128">
              <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
            </v-avatar>
            <h2>Florian Bartl</h2>
          </v-col>
        </v-row>
      </v-responsive>-->

      <div class="py-6"></div>

      <v-btn color="grey" :to="{name: 'Kontakt'}" outlined large>
        <span class="grey--text text--darken-1 font-weight-bold">Kontaktieren Sie uns</span>
      </v-btn>
     <!-- <div class="py-3"></div>
      <v-btn color="grey" @click="$vuetify.goTo('#about')" outlined large>
        <span class="grey--text text--darken-1 font-weight-bold">Erfahren Sie mehr über uns</span>
      </v-btn>-->
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  name: "Servicessection",
  data: () => ({
    show: false
  })
};
</script>