import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Forstwirtschaft from "../views/Forstwirtschaft.vue";
import Landwirtschaft from "../views/Landwirtschaft.vue";
import Vermessung from "../views/Vermessung.vue";
import Beratung from "../views/Beratung.vue";
import Impressum from "../views/Impressum.vue";
import Kontakt from"../views/Kontakt.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { 

      title: 'Home' 
    }
  },
  {
    path: "/forstwirtschaft",
    name: "Forstwirtschaft",
    component: Forstwirtschaft,
    meta: { 

      title: 'Forstwirtschaft' 
    }
  },
  {
    path: "/landwirtschaft",
    name: "Landwirtschaft",
    component: Landwirtschaft,
    meta: { 

      title: 'Landwirtschaft' 
    }
  },
  {
    path: "/Vermessung",
    name: "Vermessung",
    component: Vermessung,
    meta: { 

      title: 'Vermessung' 
    }
  },
  {path: "/Beratung",
   name: "Beratung",
   component: Beratung,
   meta: { 

    title: 'Beratung' 
  }
  },
  {
   path: "/Kontakt",
   name: "Kontakt",
   component: Kontakt,
   meta: { 

    title: 'Kontakt' 
  }
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: { 

      title: 'Impressum' 
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to && to.hash) {
      return {
          selector: to.hash,
          offset: { x: 0, y: 80 }, // avoid blocking the view when having fixed components
          behavior: 'smooth'
      };
  } else if (savedPosition) {
      return savedPosition;
  } else {
      return { x: 0, y: 0 };
  }
  }
});
router.beforeEach((to, from, next) => { 

  document.title = to.meta.title 

  next() 

}) 

export default router;
