<template>
  <v-main>
    
    <Contact />

  </v-main>
</template>

<script>
// @ is an alias to /src
import Contact from "@/components/sections/Contact.vue";

export default {
  name: "Kontakt",
  components: {
    Contact,
  }
};
</script>
