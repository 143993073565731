<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Dienstleistung für die Forstwirtschaft</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
            <v-responsive
                class="mx-auto title font-weight-light mb-8, text-left"
                max-width="720">
                <h1 id="Digitalisierung-und-Georeferenzierung">Digitalisierung und Georeferenzierung</h1><br>
                    Das Arbeiten mit veralteten, analogen Forstkarten, verhindert oft eine effiziente Kommunikation von Bestandsdaten. Dies gilt z.B. im Schadensfall gleichermaßen für die Vermittlung von Information im Innenverhältnis wie auch bei der Koordination des Einsatzes von Subunternehmern. 
                    Wir digitalisieren Ihre Bestandskarten, georeferenzieren jeden Punkt und pflegen sie in Ihr System ein. Moderne, präzise Forstkarten auf Basis von Luftaufnahmen sind zentimetergenau und dynamisch anpassbar. Außerdem können sie auf beliebig vielen Geräten aufgerufen, bearbeitet und synchronisiert werden.
                <br>
                    Für die Kartierung können wir auf ein breites Sortiment an Fernerkundungsdaten zurückgreifen. Dies ermöglicht individuelle und preislich flexible Lösungen - von der Schadensermittlung bis zum Monitoring.
                    <br>
                    Unsere Methodik umfasst hierbei unter anderem:
                    <br><br>
                   <ul>
                <li><strong> Einsatz moderner Satellitendaten (Auflösung ~ 50 cm)</strong></li>
                <br>
                <li><strong> hochpräzise LIDAR-Vermessungsdaten (Genauigkeit ~ 20 cm)</strong></li>
                <br>
                <li><strong> Fortgeschrittene Algorithmen für Baumerkennung und Datenverarbeitung</strong></li>
                <br>
                <li><strong> Verwendung industrieller Hochleistungsdrohnen (Reichweite ~ 150 km), ausgerüstet mit sensibelster Sensorik (Hyperspektralkamera mit ~10 nm spektraler Auflösung, 3 cm GSD, sowie bildgebende Thermalsensorik) </strong></li>
                <br>
                </ul>
                <br><br>

                 <h2 id="Gesundheitskartierung-Inventarisierung-und-Monitoring">Gesundheitskartierung, Inventarisierung und Monitoring</h2><br>
                     Der Klimawandel und die hiermit einhergehende Trockenheit machen v.a. wertvollen Fichtenbeständen zu schaffen und stellen Waldbewirtschafter vor große Probleme. Um gefährdete Kulturen frühzeitig zu erkennen und Bäume präventiv zu entnehmen, bedarf es einer strategischen Risikoeinschätzung.<br><br>
                     Wir erstellen Ihnen mittels hochauflösender Lidardaten in Kombination mit Drohnen- und Satellitenaufnahmen, unter Verwendung modernster Analysemethoden eine individuelle und interaktive Risikokartierung, sowie die Inventarisierung Ihres Bestands.
                     Diese beinhaltet die präzise Vermessung jedes einzelnen Baumes, die Vermessung und die Bewertung von Verjüngungsflächen. Alle Daten sind mit der jeweiligen Baum-ID verknüpft und mit den Flächenkoordinaten in einer Datenbank hinterlegt. Die entstandenen digitalen Karten und Tabellen sind mit bestehendem Kartenmaterial und Formatierungsformen kombinierbar und können nach belieben mobil oder vom Büro aus bearbeitet & synchronisiert werden. 
                    Dies ermöglicht Ihnen kurzgesagt:
                 <br><br>
                <ul>
                <li><strong> Genaue Identifizierung und Lokalisierung von Schadholz</strong></li>
                <br>
                <li><strong> Baumerkennung mit präziser Georeferenzierung & Erstellung einer interaktiven Baumdatenbank</strong></li>
                <br>
                <li><strong> Einteilung in immmergrüne und sommergrüne Vegetation</strong></li>
                <br>
                <li><strong> Ermittlung der Höhe jedes Baumes sowie des Schwergrads der Schädigung des Baumes</strong></li>
                <br>
                <li><strong> Vermessung/Bewertung von Verjüngungsflächen</strong></li>
                <br>
                <li><strong> Monitoring der Gesundheitsentwicklung jedes einzelnen Baumes</strong></li>
                <br>
                <li><strong> Einteilung und Kommentierung einzelner Bäume und Flächen zur digitalen und synchronisierten Führung von Bestandsbüchern im Büro & im Feld </strong></li>
                </ul>
                <br><br>

                <h3 id="Integration">Integration</h3><br>
                    Die Verarbeitung von digitalen Daten und die Einbindung von Vermessungsdaten in ein GIS (Geo Informations System) sowie die Etablierung des GIS an sich können eine Hürde bei der Modernisierung forstwirtschaftlicher Arbeit darstellen. Hierbei unterstützen wir Sie gerne und bieten Ihnen einen, auf Ihre Bedürfnisse abgestimmten Service. Unsere Dienstleistung beinhaltet hierbei:
                <br><br>
                <ul>
                <li><strong> Beratung und Installation eines GIS für Ihren Betrieb</strong></li>
                <br>
                <li><strong> Aufbau & Bereitstellung von digitaler Infrastruktur und Vernetzung (z.B. WMS-Server Integration)</strong></li>
                <br>
                <li><strong> Breitstellung des Kartenmaterials in den gängigen Formaten</strong></li>
                <br>
                <li><strong> Integration der Karten in Ihr System</strong></li>
                <br>
                <li><strong> Live-Support bei allen Fragen und Problemen rund um GIS (per Telefon, Mail, Remote-Zugriff oder auf Wunsch auch persönlich vor Ort), Hilfe bei der Umsetzung von individuellen GIS-Abfragen und Berechnungen </strong></li>
                <br>
                <li><strong> Schulungen zu Anwendung & Vereinfachter selbstständiger Bearbeitung/Anpassung/Pflege in Büro und Feld</strong></li>
                </ul>
            </v-responsive>
        </v-col>
        <v-col cols="12" sm="6">
          <v-responsive
                class="mx-auto title font-weight-light mb-8, text-left"
                max-width=100%>
                <img-comparison-slider>
    <!-- eslint-disable -->
                      <img
                        slot="first"
                        style="width: 100%"
                        src="@/assets/3D-Untergrund.jpg" alt="Lidar Rekonstruktion Gelände"
                      />
                      <img
                        slot="second"
                        style="width: 100%"
                        src="@/assets/3D-Rekonstruktion.jpg" alt="Lidar Rekonstruktion Landschaft"
                      />
    <!-- eslint-enable -->
                </img-comparison-slider>
                Dreidimensionale Vermessung des Untergrunds + des Bestands inklusive der Verjüngungsflächen
                                                          <v-divider class="mb-8"></v-divider>
                  <img-comparison-slider>
    <!-- eslint-disable -->
                      <img
                        slot="first"
                        style="width: 100%"
                        src="@/assets/Baumkarte.jpg" alt="Wald Luftaufnahme Satellit"
                      />
                      <img
                        slot="second"
                        style="width: 100%"
                        src="@/assets/Baumkarte_Kacheln.jpg" alt="Baum Gesundheit Kataster"
                      />
    <!-- eslint-enable -->
                </img-comparison-slider>
                Baumerkennung und Anlage einer Baumdatenbank inklusive Gesundheitszustands/Gesundheitsentwicklung, Baumhöhe, Position und Baumtyp
                                                          <v-divider class="mb-8"></v-divider>
                <img-comparison-slider>
    <!-- eslint-disable -->
                      <img
                        slot="first"
                        style="width: 100%"
                        src="@/assets/Verjuengung_1.jpg" alt="Verjüngungsanalyse"
                      />
                      <img
                        slot="second"
                        style="width: 100%"
                        src="@/assets/Verjuengung_2.jpg" alt="Verjüngungsanalyse Lidar"
                      />
    <!-- eslint-enable -->
                  </img-comparison-slider>
                  Vermessung und Bewertung von Verjüngungsflächen, auch unter dem Schirm
                                                        <v-divider class="mb-8"></v-divider>
                <img
                        style="width: 100%"
                        src="@/assets/Drohne.jpg" alt="Hochauflösende NDVI Luftaufnahme"
                        lazy-src="@/assets/Drohne_lazy.jpg"
                      />   
                      Hochauflösende Drohnenaufnahmen zur genauen Ermittlung von Schaden und Gesundheitswerten                                     
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "Service-Forstwirtschaft",
    data () {
      return {
        interval: {},
        value: 0,
      }
    },
    beforeDestroy () {
      clearInterval(this.interval)
    },
    mounted () {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 10
      }, 1000)
    },
};
</script>
