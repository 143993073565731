<template>
  <v-main>
    <Cover
      textclass="white--text text-center"
      coverurl="https://images.unsplash.com/photo-1628158088936-68ccaaa400dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      alt="GNSS System für terrestrische Vermessung"
      text1="Ihr Partner für"
      text2="Vermessung und Analyse"
    />
    <ServiceBauwirtschaft />
    <Servicessection />
     <!--<Contact />-->
  </v-main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/sections/Cover.vue";
import ServiceBauwirtschaft from "@/components/sections/Service-Bauwirtschaft.vue";
import Servicessection from "@/components/sections/Servicessection.vue";

export default {
  name: "Vermessung",
  components: {
    Cover,
    ServiceBauwirtschaft,
    Servicessection
  }
};
</script>
