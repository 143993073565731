<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Beratung und technische Unterstützung</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-responsive
            class="mx-auto title font-weight-light mb-8, text-left"
            max-width="720">
            <h1 id="Planung-und-Konzeption">Planung & Konzeption</h1><br>
                Zusammen mit unseren Kunden erstellen wir individualisierte Konzepte zur Einbindung von GIS-basierten Lösungen in den Unternehmensalltag.
                Diese reichen von der einfachen Implementierung eines GIS, über die Zuschaltung eines eigenen Serversystems, bis hin zur Koordination einer Vielzahl 
                von Nutzern und der Einbindung und automatischen Verarbeitung von Lidar, Standort und Satellitendaten. Zusätzlich überprüfen wir gerne die Machbarkeit von Ihnen erstellter 
                Konzepte <br><br>

            <h2 id="Sourcing">Sourcing</h2><br>
                Die Anschaffung und Implementierung von technischen Hilfsmitteln wie Drohnen, Sensorik jeglicher Art, aber auch von Satellitendaten und Satellitentasking stellen zusammen mit der Einsatzkoordination gleich mehrere Anforderungen
                an den potentiellen Nutzer. So muss einerseits die passende Geräteleistung für den jeweiligen Einsatz sichergestellt sein, andererseits die Gerätekommunikation und die,
                auf die Datenaufnahme folgende, digitale Verarbeitung mit passender Software sichergestellt werden.
                <br><br>
                Mit unserer Erfahrung in diesem, noch sehr chaotischen Markt können wir exakt den Kundenwünschen entsprechend, individuelle technische Lösungen sourcen. So können konkrete Kaufempfehlungen ausgesprochen und im Falle von Satellitendaten, Tasking-Konzepte
                ausgearbeitet werden.<br><br>

            <h3 id="Technische-Implementierung">Technische Implementierung</h3><br>
                Bei der Auswahl, dem Einsatz und der Lagerung von technischen Hilfsmitteln gibt es viele entscheidende Faktoren. Wir
                unterstützen Sie hierbei mit der Installation von praxiserprobten Prozessabläufen für eine reibungslose Einbindung von moderner Technik in Ihren Betriebsalltag<br><br> 

            <h4 id="Support">Support</h4><br>
                Nicht jeder Nutzer wird täglich mit den, von uns angebotenen Systemen und Lösungen arbeiten und man kann kaum von jedem Mitarbeiter erwarten ein GIS- oder Technikexperte zu sein.
                Die Arbeit mit diesen Systemen wird im Alltag somit oft von kleineren Wissenslücken und Problemen ausgebremst. Auch hier möchten wir unseren Kunden betreuen und bieten 
                einen vollumfänglichen Supportservice.
                 <br><br>
                 <ul>
                <li><strong>Schulungen, Weiterbildungen und Demonstrationen</strong></li>
                <br>
                <li><strong>Hilfestellung per Telefon, Email oder Remote Zugriff durch erfahrene Mitarbeiter</strong></li>
                <br>
                <li><strong>Pflege eines Serversystems und Aktualisierung von Daten</strong></li>
                <br>
                <li><strong>Unkomplizierte und unmittelbare Unterstützung bei neu auftretenden Fragestellungen (Sie brauchen eine spezifische Berechnung oder suchen nach einem passenden Algorithmus)</strong></li>
                <br>
                <li><strong>Individuelle Kartenberechnung und Ausgabe von fertigen Paketen (.shp files und Tabellen) durch unsere Mitarbeiter</strong></li>
                
                </ul>
                <br><br>
            <h5 id="Uebernahme-von-Prozessen">Übernahme von Prozessen</h5><br>
                Bei der Datenverarbeitung übernehmen wir jegliche Teilprozesse um Ihnen bei der Erstellung Ihrer eignenen Analysen zu helfen. Die Daten können hierzu natürlich von Ihnen 
                aufgenommen und einfach an uns übermittelt werden. Wir können dann je nach Kundenwunsch die Generierung von
                <br><br>
                 <ul>
                <li><strong>3D Punktwolken oder fertigen texturierten Modellen</strong></li>
                <br>
                <li><strong>Orthokarten</strong></li>
                <br>
                <li><strong>Vegetations- und anderer spektraler Indizes</strong></li>
                <br>
                <li><strong>fertiger Analysepakete und Tabellen</strong></li>
                </ul>
                <br>
                für Sie übernehmen.
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="6">
          <v-responsive
                class="mx-auto title font-weight-light mb-8, text-left"
                max-width=100%>
                <v-img
                contain
                max-width=100%
                src="https://images.unsplash.com/photo-1532622785990-d2c36a76f5a6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Beratung"
                > 
                </v-img>
                        <v-divider class="mb-8"></v-divider>
                <v-img
                contain
                max-width=100%
                src="@/assets/Drohne_Bild.jpg" alt="Bild Drohne Starrflügler" 
                lazy-src="@/assets/Drohne_Bild_lazy.jpg">
                        <template v-slot:placeholder>
                        <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center">
                                    <v-progress-circular
                                      :size="70"
                                      :width="7"
                                      :value="value"
                                         class="v-progress-circular--visible"
                                         color="grey lighten-5">
                                    </v-progress-circular>
                        </v-row>
                        </template>
                </v-img>
                        <v-divider class="mb-8"></v-divider>
                <v-img
                contain
                max-width=100%
                src="@/assets/wip.jpg" alt="Prozess 3D-Rekonstruktion Lidar"
                lazy-src="@/assets/wip_lazy.jpg">
                        <template v-slot:placeholder>
                        <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center">
                                    <v-progress-circular
                                      :size="70"
                                      :width="7"
                                      :value="value"
                                         class="v-progress-circular--visible"
                                         color="grey lighten-5">
                                    </v-progress-circular>
                        </v-row>
                        </template>
                </v-img>
                        <v-divider class="mb-1"></v-divider>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "ServiceBeratung"
};
</script>