<template>
  <section id="partner">
    <div class="py-12"></div>

   <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Unsere Partner</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="3">
          <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width=80%>
                <a href="https://www.revierimmo.de/start/" aria-label="Kratzenberg Revierimmobilien">
                <v-img
                contain
                max-width=100%
                src="@/assets/Kratzenberg Revierimmobilien.png" alt="Logo Kratzenberg Revierimmobilien"
                to="https://www.revierimmo.de/start/">
                </v-img>
                </a>
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="3">
          <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width=80%>
                <a href="https://www.odenwald-allianz.de/" aria-label="Odenwald-Allianz">
                <v-img
                contain
                max-width=100%
                src="@/assets/OA-Logo mit Wappen.svg" alt="Logo Odenwald-Allianz"
                to="https://www.odenwald-allianz.de/">
                </v-img>
                </a>
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="3">
          <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width=80%>
                <a href="https://geo-konzept.de/" aria-label="Geokonzept">
                <v-img
                contain
                max-width=100%
                src="@/assets/geo-konzept_QUERFORMAT_Farbe.jpg" alt="Logo Geokonzept"
                to="https://geo-konzept.de/">
                </v-img>
                </a>
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="3">
          <v-responsive
                class="mx-auto title font-weight-light mb-8"
                max-width=80%>
                <a href="https://www.flaxres.com/" aria-label="FlaxRes">
                <v-img
                contain
                max-width=100%
                src="@/assets/FLAXRES_Leitspruch_XL.jpg" alt="Logo Flaxres"
                to="https://www.flaxres.com/">
                </v-img>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "Partner"
};
</script>