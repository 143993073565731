<template>
  <v-main>
    <Cover
      textclass="black--text text-center"
      coverurl="https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&h=1080&q=80"
      alt="Landwirschaftlich bestelltes Feld"
      text1="Ihr Partner für zukunftsorientierte"
      text2="Landwirtschaft"
    />
    <ServiceLandwirtschaft />
    <Servicessection />
    <!--<Contact />-->
  </v-main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/sections/Cover.vue";
import ServiceLandwirtschaft from "@/components/sections/Service-Landwirtschaft.vue";
import Servicessection from "@/components/sections/Servicessection.vue";

export default {
  name: "Landwirtschaft",
  components: {
    Cover,
    ServiceLandwirtschaft,
    Servicessection,
  }
};
</script>
