<template>
  <section id="about-me">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">Vermessung & Analyse</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <v-responsive
            class="mx-auto title font-weight-light mb-8, text-left"
            max-width="720">
            <h1 id="Tief-und-Erdbau">Tief- und Erdbau</h1><br>
                Wir unterstützen bei Infrastrukturprojekten sowie Tiefbaumaßnahmen durch präzise, georeferenzierte Karten, Orthofotos, 2D- und 3D-Modelle des Bauvorhabens. Wir begleiten Ihre Bauvorhaben dabei von der Planung bis zu Fertigstellung und in der von Ihnen gewünschten Frequenz. Gerne vermessen wir auch den Fortschritt sowie das Ergebnis Ihrer erdbaulichen Maßnahmen. <br><br>
            <h2 id="Flaechenvermessung">Flächenvermessung</h2><br>
                Gerne ermitteln wir genaue Eckkoordinaten, Verlauf und Ausdehnung von Flächen wie z.B. Förderflächen in Forst- und Landwirtschaft, digitalisieren die Ergebnisse und liefern praktische, GIS-konforme Karten mit allen relevanten Daten. <br><br>
            <h3 id="3D-Modelle">3D-Modelle</h3><br>
                Wir erstellen vollumfängliche, hochauflösende, dreidimensionale Modelle eines Gebäudes sowie andere spezieller Bauwerke.
                Photogrammmetrie ermöglicht dabei eine Zustandsdokumentation und dient als Basis für die Planung weiterer Projekte. Die erhobenen Daten können in CAD weiterverarbeitet werden und bilden die Basis des Building Information Modelling (BIM). Dies ermöglicht präzises Vermessen und dynamisches Bearbeiten des Modells sowie eine gesamtheitliche, exakte Planung aller Maßnahmen. 
                Unsere 3D-Modelle beinhalten auf Wunsch auch die Innenräume des Objekts und bieten Ihnen zusätzlich zu Ihrem technischen Nutzen enorme Wettbewerbsvorteile in der Vermarktung. Sie ermöglichen eine digitale Visite „vor Ort“ sowie verbesserte Planungsmöglichkeiten des Kunden.<br><br>

            <h4 id="Thermoanalyse">Thermoanalyse</h4><br>
                Eine Thermoanalyse Ihres Objekts stellt die Energieeffizienz grafisch dar, gibt Aufschluss über die Dämmleistung insgesamt sowie in einzelnen Bereichen und gibt Ausschluss über ineffiziente Schwachstellen. Außerdem können durch unsere hochqualitativen Wärmebildaufnahmen beispielsweise Wasserschäden erkennbar gemacht und lokalisiert werden. Die Aufnahmen erfolgen vom Boden und aus der Luft und können somit alle Bereiche des Objekts abdecken. 
                <br>Eine weitere Einsatzmöglichkeit ist die Prüfung einzelner PV-Module auf die Erfüllung ihrer Funktionalität durch eine multispektrale Effizienzmessung. Zusammen mit unserer Hyperspektraltechnik, der hochpräzisen Georeferenzierung und der Flächenleistung unserer Starrflüglerdrohnen, wird der gesamte Solarpark zentimetergenau kartiert und die PV-Module auf Widerstände und Leistung im kompletten Solarspektrum überprüft. Die Möglichkeiten sind mannigfaltig. Zögern Sie daher nicht, uns zu kontaktieren, um sich über die Einsatzmöglichkeiten in Ihrem Bereich zu informieren. 
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="6">
          <v-responsive
                class="mx-auto title font-weight-light mb-8, text-left"
                max-width=100%>
                <v-img
                contain
                max-width=100%
                src="https://images.unsplash.com/photo-1517089596392-fb9a9033e05b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" alt="Tiefbaumaßname Baustelle"
                >
                </v-img>
                        <v-divider class="mb-8"></v-divider>
                <v-img
                contain
                max-width=100%
                src="https://images.unsplash.com/photo-1619468129361-605ebea04b44?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" alt="Planung und Konzeption"
                >
                </v-img>
                        <v-divider class="mb-8"></v-divider>
                <v-img
                contain
                max-width=100%
                src="https://images.unsplash.com/photo-1594818379496-da1e345b0ded?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80" alt="Photovoltaik Luftbild Fernerkundung"
                >
                </v-img>
                        <v-divider class="mb-1"></v-divider>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
    name: "ServiceBauwirtschaft"
};
</script>