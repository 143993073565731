<template>
  <section id="cover">
    <v-row no-gutters>
      <v-img
        min-height="100vh"
        max-height="100vh"
        :src="coverurl"
      >
        <v-theme-provider dark>
          <v-container fill-height>
            <v-row align="center" class="white--text mx-auto" justify="center">
              <v-col :class="textclass" cols="12" tag="h1">
                <span
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                  class="font-weight-light"
                >{{text1}}</span>

                <br />

                <span
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                  class="font-weight-black"
                >{{text2}}</span>
              </v-col>

              <v-btn aria-label="Weiter" class="align-self-end" fab outlined @click="$vuetify.goTo('#about-me')">
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-theme-provider>
      </v-img>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "Cover",
  props: ['textclass','coverurl','text1','text2']
};
</script>