<template>
  <v-main>
    <Cover
      textclass="white--text text-center"
      coverurl="https://images.unsplash.com/photo-1533073526757-2c8ca1df9f1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      alt="Richtung Beratung"
      text1="Kompetente Beratung für"
      text2="Planung, Implementierung & Einsatz"
    />
    <ServiceBeratung />
    <Servicessection />
     <!--<Contact />-->
  </v-main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/sections/Cover.vue";
import ServiceBeratung from "@/components/sections/Service-Beratung.vue";
import Servicessection from "@/components/sections/Servicessection.vue";

export default {
  name: "Beratung",
  components: {
    Cover,
    ServiceBeratung,
    Servicessection
  }
};
</script>
