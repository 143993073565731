<template>
  <v-main>
    <Cover
      textclass="white--text text-center"
      coverurl="https://images.unsplash.com/photo-1541890289-ad283927a063?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1933&q=80"
      alt="Luftbild Wald"
      text1="Ihr Partner für nachhaltige"
      text2="Forstwirtschaft"
    />
    <ServiceForstwirtschaft />
    <Servicessection />
    <!--<Contact />-->
  </v-main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/sections/Cover.vue";
import ServiceForstwirtschaft from "@/components/sections/Service-Forstwirtschaft.vue";
import Servicessection from "@/components/sections/Servicessection.vue";

export default {
  name: "Forstwirtschaft",
  components: {
    Cover,
    ServiceForstwirtschaft,
    Servicessection
  }
};
</script>
